// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-page-template-tsx": () => import("./../src/templates/pageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../src/templates/blogTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */)
}

